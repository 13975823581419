<template>
  <div class="legal-services">
    <div class="container">
      <div class="banner-static-services">
        <h4>
          {{
            $t(
              "Start your project with the support of our comprehensive legal services"
            )
          }}
        </h4>
        <p>
          {{ $t("Legal Desc") }}
        </p>
      </div>
      <Contact />
      <div class="how-help-you">
        <div class="title">
          {{ $t("How can we help you ?") }}
        </div>
        <p>
          {{ $t("How can we help Desc") }}
        </p>
        <div class="row mt-5 d-flex justify-content-center">
          <div class="col-md-2 flex-grow-1 d-flex mb-3">
            <div class="item">
              <div class="title">
                {{ $t("lawsuit_tracking") }}
              </div>
              <p>
                {{ $t("lawsuit_tracking_desc") }}
              </p>
            </div>
          </div>
          <div class="col-md-2 flex-grow-1 d-flex mb-3">
            <div class="item">
              <div class="title">{{ $t("legal_documentation") }}</div>
              <p>
                {{ $t("legal_documentation_desc") }}
              </p>
            </div>
          </div>
          <div class="col-md-2 flex-grow-1 d-flex mb-3">
            <div class="item">
              <div class="title">{{ $t("contract_management") }}</div>
              <p>{{ $t("contract_management_desc") }}</p>
            </div>
          </div>
          <div class="col-md-2 flex-grow-1 d-flex mb-3">
            <div class="item">
              <div class="title">{{ $t("legal_consultations") }}</div>
              <p>
                {{ $t("legal_consultations_desc") }}
              </p>
            </div>
          </div>
          <div class="col-md-2 flex-grow-1 d-flex mb-3">
            <div class="item">
              <div class="title">{{ $t("representation_litigation") }}</div>
              <p>
                {{ $t("representation_litigation_desc") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Slider
        :slides="[
        { title1: $t('legal_documentation_solution'), title2: $t('In the Kingdom of Saudi Arabia'), description: $t('legal_documentation_desc_solution') },
        { title1: $t('arbitration_commercial_settlements'), title2: $t('In the Kingdom of Saudi Arabia'), description: $t('arbitration_commercial_settlements_desc') },
        { title1: $t('legal_consultations_solution'), title2: $t('In the Kingdom of Saudi Arabia'), description: $t('legal_consultations_desc_solution') },
        { title1: $t('contract_agreement_management'), title2: $t('In the Kingdom of Saudi Arabia'), description: $t('contract_agreement_management_desc') },
        { title1: $t('debt_collection_enforcement'), title2: $t('In the Kingdom of Saudi Arabia'), description: $t('debt_collection_enforcement_desc') },
        ]"
        :Imag="`/slider-legal.png`"
        :cta="{ text: $t('Request our legal support today'), link: '/services' }"

      />
    <div class="how-help-you">
        <div class="title">
          {{ $t("What we offer you !") }}
        </div>
        <p>
          {{
            $t(
              "Etmaam Services provides a wide range of services amounting to more than 500 services that establishments need within the Kingdom of Saudi Arabia. Whether through its team or through partnership with Etmam partners, it has a team and qualified partners to complete the services with high accuracy and in a short time."
            )
          }}
        </p>
        <div class="row what-we-offer mt-5">
          <div class="col-md-4 mb-3">
            <div class="item">
              <img
                src="@/assets/img/services-page/what-we-offer-1.png"
                alt=""
              />
              <div class="title">
                {{ $t("Drafting and reviewing contracts") }}
              </div>
              <p>
                {{
                  $t(
                    "Preparing and reviewing contracts and agreements to ensure that the client’s rights and obligations are legally and clearly protected."
                  )
                }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img
                src="@/assets/img/services-page/what-we-offer-2.png"
                alt=""
              />
              <div class="title">
                {{ $t("Representing clients before the courts") }}
              </div>
              <p>
                {{
                  $t(
                    "Representing clients in civil and criminal cases, commercial lawsuits and other judicial disputes"
                  )
                }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img
                src="@/assets/img/services-page/what-we-offer-3.png"
                alt=""
              />
              <div class="title">{{ $t("Legal consultations") }}</div>
              <p>
                {{
                  $t(
                    "Providing advice and consultations in various legal fields such as commercial law, civil law, labor law, and more fields"
                  )
                }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img
                src="@/assets/img/services-page/what-we-offer-4.png"
                alt=""
              />
              <div class="title">
                {{ $t("Legal compliance and compliance") }}
              </div>
              <p>
                {{
                  $t(
                    "Assisting companies in understanding and complying with local and international laws and regulations related to their business activities"
                  )
                }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img
                src="@/assets/img/services-page/what-we-offer-5.png"
                alt=""
              />
              <div class="title">
                {{ $t("Arbitration and dispute settlement") }}
              </div>
              <p>
                {{
                  $t(
                    "Providing arbitration and mediation services as an alternative to litigation in the courts to settle disputes in a more cost- and time-effective manner"
                  )
                }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img
                src="@/assets/img/services-page/what-we-offer-6.png"
                alt=""
              />
              <div class="title">{{ $t("Tax and financial issues") }}</div>
              <p>
                {{
                  $t(
                    "Providing legal advice on tax issues and financial planning, including defending clients in tax disputes"
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    Contact: defineAsyncComponent(() =>
      import("./component/contact-services.vue")
    ),
    Slider: defineAsyncComponent(() =>
      import("./component/slider-static-services.vue")
    ),
  },
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">0' + (index + 1) + "</span>";
        },
      },
    };
  },
};
</script>
